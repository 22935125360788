import "@inovua/reactdatagrid-enterprise/index.css";
import {
  AppBar,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  FormLabel,
  Grid,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { withStyles } from "@mui/styles";
import React from "react";
import "react-toastify/dist/ReactToastify.css";
/*----------------------Cac control trong project su dung den trong form----------------*/
import moment from "moment";
import MyDateEditor from "../components/MyDateEditor";
import SimpleDialog from "../components/SimpleDialog";
import { formStyles, handleServerError, showError } from "../components/common";

/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../lib/MyLib");
const server = require("../lib/server");
/*-----------------------Cac dinh nghia ----------------------------------*/
const englishUI = true;
/*-----------------------Dinh nghia cac label tieng Anh, tieng Viet su dung trong form*/
const formTitle = englishUI ? "Delete Cost Plan" : "Xóa kế hoạch chi phí";
const fieldLabels_EN = {
  Nam: "Year:",
  NgayLap: "Created date:"
};
const fieldLabels_VN = {
  Nam: "Năm:",
  NgayLap: "Ngày lập:"
};
const fieldLabels = englishUI ? fieldLabels_EN : fieldLabels_VN;
const buttonLabels = englishUI
  ? {
    btnSave: "Save",
    btnClose: "Close"
  }
  : {
    btnSave: "Lưu",
    btnClose: "Thoát"
  };
//ham filter su dung cho combobox
/*------------------------------------Mot so ham xu ly du lieu --------------------*/
function validate_Data(data, atts) {
  //validate danh sach truong khong duoc trong'
  var ret = myLib.validateFields(data, [
    "Nam",
    "NgayLap"
  ].reduce((obj, item) => Object.assign(obj, { [item]: { "allowEmpty": "false" } }), {}));

  ret.error = Object.keys(ret).length > 0;

  return ret;
}

/*------------------------------------FORM--------------------------------------*/
class CostPlanDeleteDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSaved: false, //du lieu da tung duoc save hay chua
      DanhMuc: {},
      validateStatus: { error: false }, //trang thai validate
      after: {
        Nam: new Date().getFullYear(),
        NgayLap: moment()
      }
    };
  }

  //call khi user thay doi trang
  static getDerivedStateFromProps(nextProps) {
    return { open: nextProps.open };
  }

  componentDidMount() { }

  saveField = (fieldName, value) => {
    var s = this.state; //new value

    s.after[fieldName] = value;

    s.dataChanged = true;
    this.setState({ saving: false });
  };
  saveData = (obj, callback) => {
    var s = {};
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    s.after = { ...this.state.after, ...obj };
    this.setState(s, () => {
      if (callback) {
        callback();
      }
    });
  };
  saveRecord = (index, value) => {
    var s = { after: { ...this.state.after } }; //new value
    s.after.NoiDung[index] = value;
    s.dataChanged = true;
    this.setState(s);
  };

  doSave = () => {
    try {
      var check = validate_Data(this.state.after);
      if (!check.error) {
        server
          .post("ChiPhi/DoRequest", {
            Function: "Proc_CP_NganSachNam_Delete",
            ThamSo: {
              ...this.state.after,
              NgayLap: moment(this.state.after.NgayLap).utc().hour(0).minute(0).second(0)
            },
          })
          .then(() => {
            this.props.close(true);
          })
          .catch((error) => {
            handleServerError(error);
            this.setState({ saving: false });
          });
      } else {
        this.setState({ validateStatus: check, saving: false });
        showError("Vui lòng kiểm tra các trường đánh dấu đỏ!");
      }
    } catch (error) {
      this.setState({ saving: false });
      handleServerError(error);
    }
  };

  handleClose = () => {
    if (this.state.dataChanged) {
      this.setState({ showConfirmExit: true });
    } else {
      this.props.close(this.state.dataSaved);
    }
  };

  render() {
    const { classes, theme } = this.props;
    return (
      <Dialog
        open={this.props.open}
        maxWidth={"xl"}
        onClose={() => { }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <AppBar sx={{ position: "relative" }}>
          <Stack direction="row">
            <Typography
              sx={{ ml: 2, flex: 1, verticalAlign: "center" }}
              variant="h6"
              component="div"
            >
              {formTitle}
            </Typography>
            <Stack direction="row" spacing={4}>
              {this.state.loading_data ? (
                <CircularProgress color="warning" size={24} />
              ) : null}
              {this.state.saving ? (
                <CircularProgress color="inherit" size={24} />
              ) : null}{this.state.loading ? (
                <CircularProgress color="primary" size={24} />
              ) : (<></>)}
              <Button
                color="success"
                variant="contained"
                disabled={!this.state.dataChanged || this.state.saving}
                onClick={() => this.doSave()}
              >
                {buttonLabels.btnSave}
              </Button>
              <Button
                color="success"
                variant="contained"
                disabled={this.state.saving}
                onClick={this.handleClose}
              >
                {buttonLabels.btnClose}
              </Button>
            </Stack>
          </Stack>
        </AppBar>
        <DialogContent>
          <div
            style={{
              minWidth: 400,
              minHeight: 30,
              padding: 2,
              fontSize: 10,
              wordWrap: "break-word",
              overflow: "hidden",
            }}
          >
            <Grid container columns={24} rowSpacing={0} columnSpacing={1}>
              <Grid item xs={4}>
                <FormLabel>
                  {fieldLabels.Nam}
                </FormLabel>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  type="number"
                  InputProps={{ inputProps: { min: 2020, max: new Date().getFullYear() } }}
                  value={this.state.after.Nam}
                  onChange={(e) => {
                    this.saveField("Nam", +e.target.value);
                  }}
                  error={this.state.validateStatus.Nam !== undefined} />
              </Grid>
              <Grid item xs={6}>
                <FormLabel>
                  {fieldLabels.NgayLap}
                </FormLabel>
              </Grid>
              <Grid item xs={8}>
                <MyDateEditor
                  fullWidth
                  value={this.state.after.NgayLap}
                  onChange={(value) => {
                    this.saveField("NgayLap", value);
                  }}
                  error={this.state.validateStatus.NgayLap !== undefined}
                />
              </Grid>
            </Grid>
          </div>
        </DialogContent>

        <SimpleDialog
          confirm
          message="Bạn chắc chắn muốn thoát ra ?"
          open={this.state.showConfirmExit}
          close={(ok) => {
            this.setState({ showConfirmExit: false });
            if (ok) {
              this.props.close(this.state.dataSaved);
            }
          }}
        />
      </Dialog>
    );
  }
}
export default withStyles(formStyles, { withTheme: true })(CostPlanDeleteDialog);
