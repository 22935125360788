import "@inovua/reactdatagrid-enterprise/index.css";
import {
  AppBar,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  FormLabel,
  Grid,
  OutlinedInput,
  Stack,
  Typography
} from "@mui/material";
import { withStyles } from "@mui/styles";
import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

/*----------------------Cac control trong project su dung den trong form----------------*/
import MySelectFilter from "../components/MySelectFilter";
import SimpleDialog from "../components/SimpleDialog";
import { formStyles, handleServerError, showError } from "../components/common";
import FieldNameDialog from "./FieldNameDialog";
/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../lib/MyLib");
const server = require("../lib/server");
/*-----------------------Cac dinh nghia ----------------------------------*/
const englishUI = true;
/*-----------------------Dinh nghia cac label tieng Anh, tieng Viet su dung trong form*/
const formTitle = englishUI ? "Contractor" : "Công ty";
const fieldLabels_EN = {
  TenCongTy: "Contractor name:",
  DiaChi: "Address:",
  DienThoai: "Phone number:",
  Email: "Email:",
  GiamDoc: "Director:",
  TenVietTat: "Short name:",
  PhuTrach: "Person in charge:",
  HopDong: "Hợp đồng nguyên tắc:",
  LinhVuc: "Lĩnh vực:",
  MaQG: "Country:",
  CoHopDong: "Có hợp đồng:",
  CoBangGia: "Có Bảng giá:"
};
const fieldLabels_VN = {
  TenCongTy: "Tên công ty:",
  DiaChi: "Địa chỉ:",
  DienThoai: "SĐT:",
  Email: "Email:",
  GiamDoc: "Giám đốc:",
  TenVietTat: "Tên viết tắt",
  PhuTrach: "Phụ trách:",
  HopDong: "Hợp đồng nguyên tắc:",
  LinhVuc: "Lĩnh vực:",
  MaQG: "Quốc gia:",
  CoHopDong: "Có hợp đồng:",
  CoBangGia: "Có Bảng giá:"
};
const fieldLabels = englishUI ? fieldLabels_EN : fieldLabels_VN;
function getFieldNames() {
  var list = [];
  var keys = Object.keys(fieldLabels);
  for (var i = 0; i < keys.length; i++) {
    list.push({
      FieldName: keys[i],
    });
  }
  return list;
}
const listFields = getFieldNames();
const buttonLabels = englishUI
  ? {
    btnSave: "Save",
    btnClose: "Close"
  }
  : {
    btnSave: "Lưu",
    btnClose: "Thoát"
  };
//ham filter su dung cho combobox
/*------------------------------------Mot so ham xu ly du lieu --------------------*/
function validate_Data(data) {
  //validate danh sach truong khong duoc trong'
  var ret = myLib.validateFields(data, [
    "TenCongTy"
  ].reduce((obj, item) => Object.assign(obj, { [item]: { "allowEmpty": "false" } }), {}));

  ret.error = Object.keys(ret).length > 0;

  return ret;
}

/*------------------------------------FORM--------------------------------------*/
class ContractorEditDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSaved: false, //du lieu da tung duoc save hay chua
      DanhMuc: {
        DM_ChuyenMon: [],
        DM_QuocGia: [],
        DM_CoHopDong: [
          { TenCoHopDong: "Y", CoHopDong: englishUI ? "Y" : "Có" },
          { TenCoHopDong: "N", CoHopDong: englishUI ? "N" : "Đã từng có" },
          { TenCoHopDong: "Non", CoHopDong: englishUI ? "Non" : "Không có" }
        ],
        DM_CoBangGia: [
          { TenCoBangGia: "Y", CoBangGia: englishUI ? "Y" : "Có" },
          { TenCoBangGia: "N", CoBangGia: englishUI ? "N" : "Đã từng có" },
          { TenCoBangGia: "Non", CoBangGia: englishUI ? "Non" : "Không có" }
        ]
      },
      validateStatus: { error: false }, //trang thai validate
      after: {
        TenCongTy: null,
        DiaChi: null,
        DienThoai: null,
        Email: null,
        GiamDoc: null,
        TenVietTat: null,
        PhuTrach: null,
        HopDong: null,
        LinhVuc: null,
        MaQG: null,
        CoHopDong: null,
        CoBangGia: null,
        ...(props.edittingRecord ? props.edittingRecord : {})
      }
    };
  }

  //call khi user thay doi trang
  static getDerivedStateFromProps(nextProps) {
    return { open: nextProps.open };
  }

  componentDidMount() {
    this.loadDanhMuc("DM_ChuyenMon,DM_QuocGia", () => { });
  }

  //load du lieu danh muc theo danh sach table
  loadDanhMuc(danhsach, nextFunc) {
    //load danh muc
    if (danhsach) {
      server
        .post("ChiPhi/DoRequest", {
          Function: "Proc_Table_GetAll",
          ThamSo: {
            TableNames: danhsach,
          },
        })
        .then((response) => {
          this.setState(
            {
              DanhMuc: {
                ...this.state.DanhMuc,
                ...response,
              },
            },
            () => nextFunc()
          );
        })
        .catch((error) => {
          showError(error);
          nextFunc();
        });
    }
  }

  saveField = (fieldName, value) => {
    var s = this.state; //new value

    s.after[fieldName] = value;

    s.dataChanged = true;
    this.setState({ saving: false });
  };

  saveData = (obj) => {
    var s = this.state;
    s = { ...s, after: { ...s.after, ...obj } };
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    this.setState(s);
  };

  doSave = () => {
    try {
      var check = validate_Data(this.state.after);
      console.log(check, this.state.after);
      if (!check.error) {
        this.setState({ validateStatus: check, saving: true, errorLog: "" });
        server
          .post("ChiPhi/DoRequest", {
            Function: "Proc_DM_CongTy_Update",
            ThamSo: {
              ...this.state.after
            }
          })
          .then(() => {
            this.setState(
              {
                dataSaved: true,
                dataChanged: false,
                canSaved: false,
                saving: false,
                errorMessage: "",
                errorLog: ""
              },
              () => {
                this.props.close(this.state.dataSaved);
              }
            );
          })
          .catch((error) => {
            handleServerError(error);
            this.setState({ saving: false });
          });
      } else {
        this.setState({ validateStatus: check, saving: false });
        showError("Vui lòng kiểm tra các trường đánh dấu đỏ!");
      }
    } catch (error) {
      this.setState({ saving: false });
      handleServerError(error);
    }
  };

  handleClose = () => {
    if (this.state.dataChanged) {
      this.setState({ showConfirmExit: true });
    } else {
      this.props.close(this.state.dataSaved);
    }
  };
  render() {
    const { classes, theme } = this.props;
    return (
      <Dialog
        open={this.props.open}
        maxWidth={"xl"}
        onClose={() => { }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <AppBar sx={{ position: "relative" }}>
          <Stack direction="row">
            <Typography
              sx={{ ml: 2, flex: 1, verticalAlign: "center" }}
              variant="h6"
              component="div"
            >
              {formTitle}
            </Typography>
            <Stack direction="row" spacing={4}>
              {this.state.loading_data ? (
                <CircularProgress color="warning" size={24} />
              ) : null}
              {this.state.saving ? (
                <CircularProgress color="inherit" size={24} />
              ) : null}{this.state.loading ? (
                <CircularProgress color="primary" size={24} />
              ) : (<></>)}
              <Button
                color="success"
                variant="contained"
                disabled={!this.state.dataChanged || this.state.saving}
                onClick={() => this.doSave()}
              >
                {buttonLabels.btnSave}
              </Button>
              <Button
                color="success"
                variant="contained"
                disabled={this.state.saving}
                onClick={this.handleClose}
              >
                {buttonLabels.btnClose}
              </Button>
            </Stack>
          </Stack>
        </AppBar>
        <DialogContent>
          <div
            style={{
              minWidth: 800,
              minHeight: 30,
              padding: 2,
              fontSize: 10,
              wordWrap: "break-word",
              overflow: "hidden",
            }}
          >
            <Grid container columns={24} rowSpacing={0} columnSpacing={1}>
              <Grid item xs={4}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "TenCongTy",
                    })
                  }
                >
                  {fieldLabels.TenCongTy}
                </FormLabel>
              </Grid>
              <Grid item xs={8}>
                <OutlinedInput
                  fullWidth
                  value={this.state.after.TenCongTy}
                  onChange={(event) => {
                    this.saveField("TenCongTy", event.target.value);
                  }}
                  error={this.state.validateStatus.TenCongTy !== undefined}
                />
              </Grid>
              <Grid item xs={4}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "GiamDoc",
                    })
                  }
                >
                  {fieldLabels.GiamDoc}
                </FormLabel>
              </Grid>
              <Grid item xs={8}>
                <OutlinedInput
                  fullWidth
                  value={this.state.after.GiamDoc}
                  onChange={(event) => {
                    this.saveField("GiamDoc", event.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "DiaChi",
                    })
                  }
                >
                  {fieldLabels.DiaChi}
                </FormLabel>
              </Grid>
              <Grid item xs={20}>
                <OutlinedInput
                  fullWidth
                  value={this.state.after.DiaChi}
                  onChange={(event) => {
                    this.saveField("DiaChi", event.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "DienThoai",
                    })
                  }
                >
                  {fieldLabels.DienThoai}
                </FormLabel>
              </Grid>
              <Grid item xs={8}>
                <OutlinedInput
                  fullWidth
                  value={this.state.after.DienThoai}
                  onChange={(event) => {
                    this.saveField("DienThoai", event.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "Email",
                    })
                  }
                >
                  {fieldLabels.Email}
                </FormLabel>
              </Grid>
              <Grid item xs={8}>
                <OutlinedInput
                  fullWidth
                  value={this.state.after.Email}
                  onChange={(event) => {
                    this.saveField("Email", event.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "TenVietTat",
                    })
                  }
                >
                  {fieldLabels.TenVietTat}
                </FormLabel>
              </Grid>
              <Grid item xs={8}>
                <OutlinedInput
                  fullWidth
                  value={this.state.after.TenVietTat}
                  onChange={(event) => {
                    this.saveField("TenVietTat", event.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "PhuTrach",
                    })
                  }
                >
                  {fieldLabels.PhuTrach}
                </FormLabel>
              </Grid>
              <Grid item xs={8}>
                <OutlinedInput
                  fullWidth
                  value={this.state.after.PhuTrach}
                  onChange={(event) => {
                    this.saveField("PhuTrach", event.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "HopDong",
                    })
                  }
                >
                  {fieldLabels.HopDong}
                </FormLabel>
              </Grid>
              <Grid item xs={8}>
                <OutlinedInput
                  fullWidth
                  value={this.state.after.HopDong}
                  onChange={(event) => {
                    this.saveField("HopDong", event.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "LinhVuc",
                    })
                  }
                >
                  {fieldLabels.LinhVuc}
                </FormLabel>
              </Grid>
              <Grid item xs={8}>
                <MySelectFilter
                  fullWidth
                  autoFocus={false}
                  options={this.state.DanhMuc.DM_ChuyenMon}
                  optionLabel="TenLinhVuc"
                  optionValue="LinhVuc"
                  value={this.state.after}
                  onChange={(item) => {
                    this.saveData(item);
                  }} />
              </Grid>
              <Grid item xs={4}>
                <FormLabel>
                  {fieldLabels.MaQG}
                </FormLabel>
              </Grid>
              <Grid item xs={8}>
                <MySelectFilter
                  fullWidth
                  autoFocus={false}
                  options={this.state.DanhMuc.DM_QuocGia}
                  optionLabel="TenQG"
                  optionValue="MaQG"
                  value={this.state.after}
                  onChange={(item) => {
                    this.saveData(item);
                  }} />
              </Grid>
              <Grid item xs={4}>
                <FormLabel>
                  {fieldLabels.CoHopDong}
                </FormLabel>
              </Grid>
              <Grid item xs={8}>
                <MySelectFilter
                  fullWidth
                  autoFocus={false}
                  options={this.state.DanhMuc.DM_CoHopDong}
                  optionLabel="TenCoHopDong"
                  optionValue="CoHopDong"
                  value={this.state.after}
                  onChange={(item) => {
                    this.saveData(item);
                  }} />
              </Grid>
              <Grid item xs={4}>
                <FormLabel>
                  {fieldLabels.CoBangGia}
                </FormLabel>
              </Grid>
              <Grid item xs={8}>
                <MySelectFilter
                  fullWidth
                  autoFocus={false}
                  options={this.state.DanhMuc.DM_CoBangGia}
                  optionLabel="TenCoBangGia"
                  optionValue="CoBangGia"
                  value={this.state.after}
                  onChange={(item) => {
                    this.saveData(item);
                  }} />
              </Grid>
            </Grid>
          </div>
        </DialogContent>

        <ToastContainer
          position="top-right"
          autoClose={false}
          hideProgressBar
          newestOnTop={true}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          style={{ fontSize: 12, width: 750 }}
          limit={5}
        />
        <SimpleDialog
          confirm
          message="Bạn chắc chắn muốn thoát ra ?"
          open={this.state.showConfirmExit}
          close={(ok) => {
            this.setState({ showConfirmExit: false });
            if (ok) {
              this.props.close(this.state.dataSaved);
            }
          }}
        />
        {this.state.show_thongtin_field ? (
          <FieldNameDialog
            open={true}
            FormName="ContractorEditDialog"
            ListFields={listFields}
            FieldName={this.state.Show_FieldName}
            close={() => {
              this.setState({ show_thongtin_field: false });
            }}
          />
        ) : null}
      </Dialog>
    );
  }
}
export default withStyles(formStyles, { withTheme: true })(ContractorEditDialog);
